<template>
  <div>
    <!-- main page -->
    <vs-tabs ref="sub-tabs" alignment="center">
      <vs-tab :label="labelString()">
        <div class="con-tab-ejemplo">
          <datatable
            :territories="territories"
            :item="item"
            :territory_ids="territory_ids"
            :labelString="labelString()"
            :filterInvoiceExchangeDate="filterInvoiceExchangeDate"
            :draw="draw"
            :openKeyDate="this.openKeyDate"
          ></datatable>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import datatable from "./datatable.vue";

export default {
  components: {
    datatable,
  },
  props: [
    "operatingUnit",
    "territories",
    "filterInvoiceExchangeDate",
    "item",
    "draw",
    "openKeyDate",
  ],
  data() {
    return {
      territory_ids: "",
      supplier_ids: "",
    };
  },
  watch: {
    //
    // watchedProperties: function () {
    //   this.setIDTerr();
    //   this.setIDSupplier();
    // },
  },
  mounted() {
    //
    // this.setIDTerr();
    // this.setIDSupplier();
  },
  computed: {
    watchedProperties() {
      return `${this.draw}`;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    labelString() {
      let datas = this.territories;
      let keys = Object.keys(datas);
      let count = datas.length;
      let territories = "";
      if (count > 1) {
        territories += "(";
      }
      keys.forEach(function (i) {
        // dataIDS.push(datas[i].ID)
        if (datas[i].ID != 0) {
          if (count > 1) {
            if (parseInt(i) + 1 != count) {
              territories += datas[i].Code + ",";
            } else {
              territories += datas[i].Code;
            }
          } else {
            territories += datas[i].Code;
          }
        }
      });
      if (count > 1) {
        territories += ")";
      }
      // console.log(JSON.stringify(dataIDS))
      return (
        (territories == null || territories == "" || territories == "undefined"
          ? "All"
          : territories) + `}`
      );
    },
    setIDTerr() {
      let datas = this.territories;
      let keys = Object.keys(datas);
      let count = datas.length;
      let dataIDS = "";

      keys.forEach(function (i) {
        if (datas[i].ID != 0) {
          dataIDS += datas[i].ID;
          if (parseInt(i) + 1 != count) {
            dataIDS += ",";
          }
        }
      });
      this.territory_ids = dataIDS;
    },
    setIDSupplier() {
      let datas = this.supplier;
      let keys = Object.keys(datas);
      let count = datas.length;
      let dataIDS = "";

      keys.forEach(function (i) {
        if (datas[i].ID != 0) {
          dataIDS += datas[i].ID;
          if (parseInt(i) + 1 != count) {
            dataIDS += ",";
          }
        }
      });
      this.supplier_ids = dataIDS;
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
