<template>
  <div>
    <div>
      <vs-table
        search
        stripe
        border
        description
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
      >
        <template slot="header">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <div class="btn-group ml-5">
            <vs-button
              class="ml-4 mt-2"
              color="primary"
              icon-pack="feather"
              icon="icon-prin"
              @click="handleExport('excel')"
              >Download Excel</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <!-- <vs-th></vs-th> -->
          <vs-th
            v-for="(head, index) in this.table.heads"
            :key="index"
            :sort-key="head.field"
            >{{ head.title }}</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td
              v-for="(head, index) in table.heads"
              :key="index"
              :data="data[indextr][head.field]"
              >{{ format(head, table.data[indextr][head.field]) }}</vs-td
            >
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: [
    "progress",
    "operatingUnit",
    "supplier",
    "labelString",
    "filterInvoiceExchangeDate",
    "filterPostingDate",
    "territory_ids",
    "territories",
    "item",
    "draw",
    "openKeyDate",
  ],
  mounted() {},
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  watch: {
    watchedProperties: function () {
      this.getData();
    },
  },
  computed: {
    watchedProperties() {
      return `${this.draw}||${this.search}`;
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          { title: "Branch", field: "branch" },
          { title: "Distribution Channel", field: "distribution_channel" },
          { title: "Customer Code", field: "customer_code" },
          { title: "Customer Name", field: "customer_name" },
          { title: "SO Number", field: "so_number" },
          { title: "SO Date", field: "so_date", format: this.dateFormat },
          { title: "DO Number", field: "do_number" },
          { title: "GI Number", field: "gi_number" },
          {
            title: "Bill Number",
            field: "bill_number",
          },
          {
            title: "Bill Amount",
            field: "bill_amount",
            format: this.priceFormat,
          },
          { title: "TOP", field: "top" },
          { title: "Bill Date", field: "bill_date", format: this.dateFormat },
          { title: "Paid Status", field: "paid_status" },
          { title: "Payment Aging", field: "payment_aging" },
          {
            title: "Bill Creation Date",
            field: "bill_creation_date",
            format: this.dateFormat,
          },
          {
            title: "Tukar Faktur Date",
            field: "tukar_faktur_date",
            format: this.dateFormat,
          },
          { title: "Tukar Faktur Number", field: "tukar_faktur_number" },
          {
            title: "Good Receipt ACK Number",
            field: "goods_receipt_ack_number",
          },
          { title: "Aging Tukar Faktur", field: "aging_tukar_faktur" },
          { title: "Customer PO", field: "customer_po" },
          { title: "Faktur Pajak", field: "faktur_pajak" },
          { title: "Ship To Code", field: "shipto_code" },
          { title: "Ship To Name", field: "shipto_name" },
          { title: "Supplier Code", field: "supplier_code" },
        ],
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },

    getData(page = 1) {
      if (this.draw != 0) {
        if (
          this.territories == null ||
          this.territories == "" ||
          this.territories == [] ||
          this.territories == undefined
        ) {
          return true;
        }

        this.isActive = page;
        this.$vs.loading();

        let customer_ids =
          this.$store.getters["customerFilter/getSelectedCutomerIDs"];
        if (customer_ids.includes(0)) {
          customer_ids = [];
        }

        let territory_ids =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];

        var parameter = {
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          start_invoice_exchange_date:
            this.filterInvoiceExchangeDate.startDate != null
              ? moment(this.filterInvoiceExchangeDate.startDate).format(
                  "YYYY-MM-DD"
                )
              : null,
          end_invoice_exchange_date:
            this.filterInvoiceExchangeDate.endDate != null
              ? moment(this.filterInvoiceExchangeDate.endDate).format(
                  "YYYY-MM-DD"
                )
              : null,
          territory_ids,
          customer_ids,
        };

        this.$http
          .get("/api/v1/invoice-exchange/report", {
            params: parameter,
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;
              this.setStartEnd();
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
            this.$vs.loading.close();
          });
      }
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    dateFormat(val) {
      if (!val || val.includes("0001-01-01")) {
        return "";
      }
      return moment.utc(val).format("DD MMM YYYY");
    },
    handleExport(file) {
      this.$vs.loading();
      var fileTitle =
        "invoice_exchange" +
        "_" +
        moment(this.startDate).format("YYYY-MM-DD") +
        "_-_" +
        moment(this.endDate).format("YYYY-MM-DD");

      let customer_ids =
        this.$store.getters["customerFilter/getSelectedCutomerIDs"];
      if (!customer_ids || customer_ids.includes(0)) {
        customer_ids = [];
      }

      let territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];

      this.$http
        .get("/api/v1/invoice-exchange/report/export", {
          params: {
            order: this.table.order,
            sort: this.table.sort,
            page: this.table.page,
            length: this.table.length,
            file: file,
            territory_ids: territory_ids,
            item: this.item,
            supplier_id: this.supplier,
            start_invoice_exchange_date:
              this.filterInvoiceExchangeDate.startDate != null
                ? moment(this.filterInvoiceExchangeDate.startDate).format(
                    "YYYY-MM-DD"
                  )
                : null,
            end_invoice_exchange_date:
              this.filterInvoiceExchangeDate.endDate != null
                ? moment(this.filterInvoiceExchangeDate.endDate).format(
                    "YYYY-MM-DD"
                  )
                : null,
            open_key_date:
              this.openKeyDate != null
                ? moment(this.openKeyDate).format("YYYY-MM-DD")
                : null,
            title: fileTitle,
            customer_ids,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        });
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
